:root {
  --mess-color: red;
  --background-color: white;
  --font-color: black;
}

[data-letters]:before {
  content:attr(data-letters);
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:var(--background-color);
  vertical-align:middle;
  margin-right:1em;
  color:var(--font-color);
  }

.no_padding {
  padding-right: 0px !important;
}

.badge1 {
   position:relative;
}
.badge1[data-badge]:after {
   content:attr(data-badge);
   position:absolute;
   top:-10px;
   right:-14px;
   font-size: 85%;
   font-weight: 500;
   background: #00b8d8 !important;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
   color:white;
   padding: 0.08rem 0.3rem;
   text-align:center;
   line-height:16px;
   border-radius: 0.375rem;
}

.custom-button{
   color:var(--mess-color);
   border-color:var(--mess-color);
   background:white;
}

.custom-button:hover{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
}

.custom-button:active{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
}

.custom-button:target{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
}

.custom-button:focus{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
  box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px rgba(0,0,0,.25);
}

.custom-button:active{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-button:visited{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
}

.custom-button:focus-within{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
}

.custom-button:after{
  background:var(--mess-color);
  border-color:var(--mess-color);
  color:white;
}



.custom-active{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:hover{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:active{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:target{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:focus{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:active{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:visited{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:focus-within{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}

.custom-active:after{
  background:var(--mess-color) !important;
  border-color:var(--mess-color) !important;
  color:white !important;
}




.react-tagsinput {
  background-color: #fff;
  border: 1px solid #e1e5eb;
  border-radius: 0.375rem;
  overflow: hidden;
  padding-left: 12px;
  padding-top: 12px;
  margin: 10px;
}

.react-tagsinput--focused {
  color: #495057;
  background-color: #fff;
  border-color: #007bff;
  box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}


.react-tagsinput:hover {
  border-color: #b3bdcc;
}

.react-tagsinput--focused:hover {
  border-color: #007bff;
}

.react-tagsinput-tag {
  background-color: #fff;
  border-radius: 0.375rem;
  border: 1px solid #007bff;
  color: #007bff;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #5B6067;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: auto;
}
